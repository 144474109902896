import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import _metaRespose from './meta';
import _dataResponse from './data';
import _summarybox from './summaryBox';

const axiosMockInstance = axios.create();
const axiosLiveInstance = axios.create();
export const axiosMockAdapterInstance= new AxiosMockAdapter(axiosMockInstance, { delayResponse: 2000 });
console.log("process.env.isAxioMockk",process.env.REACT_APP_IS_MOCK==="true");
export default (process.env.REACT_APP_IS_MOCK==="true") ? axiosMockInstance : axiosLiveInstance;

axiosMockAdapterInstance.onGet("/golf/service/channel/meta/shopping-page").reply(200, _metaRespose);
axiosMockAdapterInstance.onPost("/golf/service/shopping-page/shoppingPage").reply(200, _dataResponse);
axiosMockAdapterInstance.onPost("/golf/service/citupload").reply(200, {"status":"FAILURE"});
axiosMockAdapterInstance.onGet("/golf/service/clear").reply(200,{
    "response": 200,
    "errors": [],
    "screens": []
});

axiosMockAdapterInstance.onGet("/golf/service/init").reply(200,{
    "applicationId": "0524257315481",
    "taggingSwitch": "ON",
    "bmbHeaderSwitch": "ON",
    "bmbTaggingSwitch": "ON"
});
axiosMockAdapterInstance.onPost("/golf/service/channel/preProcess").reply(200,{
    "responseCode": "SUCCESS",
    "channelId": 1,
    "journeyType": "ONLINE",
    "formType": "apply",
    "nextLink": "/shopping-page"
});

axiosMockAdapterInstance.onPost("/golf/service/summaryBox").reply(200, _summarybox);