import { useState } from 'react';
import axios from './axiosAdaptor';
// import _metaRespose from './meta';
import {RequestType,ShoppingPageMetaType,ElementMapType,ElementInterface} from '../types/ShoppingPageMetaTypes'


var metaUrl = '/golf/service/channel/meta/shopping-page';

function useMeta(){
    let [metaRespose, setMetaResponse] = useState({} as ShoppingPageMetaType);
    let [meta, setMeta] = useState({} as ElementMapType);
    const [requestStatus, setRequestStatus] = useState<RequestType>('idle');
    // useEffect(() => {
      async function fetchMetaApi() {
        try {
          setRequestStatus('loading');
          const result = await axios.get(metaUrl);
          setMetaResponse(result.data);
          setMeta(mapScreenElements(result.data,'SHOPPING_PAGE_WITH_CATEGORY'));
          setRequestStatus('success');
        } catch (e) {
          setRequestStatus('failure');
        }
      }
    //   fetchData();
    // }, []);

    const getMeta = (screenId:string):ElementInterface[] => {
      let _meta = [] as ElementInterface[];
      if(requestStatus==='success'){
        let elements = (metaRespose && metaRespose.screens.filter((e)=>e.screenid===screenId)?.[0]?.elements) || null;
          _meta = elements
      }
      return _meta;
    }
    const getMetaElements = (screenId:string):ElementMapType => {
      let _meta = {} as ElementMapType;
      if(requestStatus==='success'){
        _meta = mapScreenElements(metaRespose,screenId);
      }
      return _meta;
    }
    const mapScreenElements = (screens:ShoppingPageMetaType,screenId:string):ElementMapType =>{
      let elementsMap = {} as ElementMapType;
      let elements = (screens && screens.screens.filter((e)=>e.screenid===screenId)?.[0]?.elements) || null;
      if(elements){
        elementsMap = elements.reduce((acc:ElementMapType,curr)=>{
          acc[curr.id] = curr;
          return acc;
        },{});
      }
      return elementsMap;
    }
    return {
        meta,
        getMeta,
        fetchMetaApi,
        getMetaElements,
        metaRequestStatus: requestStatus
    }
} 

export default useMeta;