import {ShoppingPageDataType} from '../types/ShoppingPageDataTypes'

// let data:ShoppingPageDataType = {
//     "responseCode": "SUCCESS",
//     "journeyType": "PS",
//     "mainBulletPointsCount": "5",
//     // "categoryMapping": "Suggested,6543,7677,8776,topOffer,6543,7677,8776",
//     //  [{
//     //     Category: 'Suggested',
//     //     pcList: [6543,7677,8776]
//     //     },{
//     //     Category: 'topOffer',
//     //     pcList: [6543,7677,8776]
//     // }],
//     "offers": [
//         {
//             "productCode": "9376",
//             "originCode": "318HD1",
//             "trackingCode": null,
//             "clouditSwitch": "Y",
//             "spProdDesc": "Forward Credit Card",
//             "cardImage": "forward-card.png",
//             "smallCardImage": "forward-card-small.png",
//             "cardIcon": "forward-icon.png",
//             "spPromoText": "Are you looking for your first credit card, or do you have a limited credit history?",
//             "productCategory": "suggested,creditBuilding,topOffer",
//             "topOffer": true,
//             "spPreApproved": false,
//             "amfValue": "£0",
//             "instantBTFlag": "N",
//             "mainBulletPoints": [
//                 "<h3>Collect 1 Avios for every £1 you spend on eligible purchases</h3><p>Put them towards flights, hotels, car hire and more. Terms and conditions apply, which you'll find under 'Barclaycard Avios reward rules' as you complete the application.</p>",
//                 "<h3>Collect 5,000 Avios if you spend £1,000 in your first 3 months</h3><p>New customers only.</p>",
//                 "<h3>British Airways cabin upgrade voucher if you spend £20,000 in a year</h3><p>To be used on an Avios Reward Flight booking – see ba.com/cabin-upgrade-voucher for terms and conditions.</p>",
//                 "<h3>Free Apple subscriptions</h3><p>Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply.</p>"
//             ],
//             "apr": "33.90",
//             "airP": "33.9",
//             "tcbiBal": "1200.00",
//             "btCapAmount": 5000.0,
//             "btFeePercentage": "null",
//             "eligibilityUrl": null,
//             "targetValue": null,
//             "btFeeMin": 0,
//             "amfValue": 0
//         },
//         {
//             "productCode": "9359",
//             "originCode": "325HD1",
//             "trackingCode": null,
//             "clouditSwitch": "Y",
//             "spProdDesc": "Up to 22 month balance transfer and up to 24 month for purchases Platinum card",
//             "cardImage": "platinum-card.png",
//             "smallCardImage": "platinum-new-small.png",
//             "cardIcon": "platinum-icon.png",
//             "spPromoText": "A great all-rounder card you can use for balance transfers and purchases.",
//             "productCategory": "purchaseOffer",
//             "topOffer": false,
//             "spPreApproved": true,
//             "amfValue": "£0",
//             "instantBTFlag": "Y",
//             "mainBulletPoints": [
//                 "<h3>Collect 1 Avios for every £1 you spend on eligible purchases</h3><p>Put them towards flights, hotels, car hire and more. Terms and conditions apply, which you'll find under 'Barclaycard Avios reward rules' as you complete the application.</p>",
//                 "<h3>Collect 5,000 Avios if you spend £1,000 in your first 3 months</h3><p>New customers only.</p>",
//                 "<h3>British Airways cabin upgrade voucher if you spend £20,000 in a year</h3><p>To be used on an Avios Reward Flight booking – see ba.com/cabin-upgrade-voucher for terms and conditions.</p>",
//                 "<h3>Free Apple subscriptions</h3><p>Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply.</p>"
//             ],
//             "apr": "21.90",
//             "airP": "21.92",
//             "tcbiBal": "1200.00",
//             "btCapAmount": 20000.0,
//             "btFeePercentage": "0",
//             "eligibilityUrl": null,
//             "targetValue": null,
//             "btFeeMin": 0,
//             "amfValue": 0
//         },
//         {
//             "productCode": "9364",
//             "originCode": "324HD1",
//             "trackingCode": null,
//             "clouditSwitch": "Y",
//             "spProdDesc": "Barclaycard Rewards",
//             "cardImage": "rewards-card.png",
//             "smallCardImage": "flex-rewards-card-small.png",
//             "cardIcon": "rewards-icon.png",
//             "spPromoText": "Good for earning cashback on your purchases.",
//             "productCategory": "rewards",
//             "topOffer": true,
//             "spPreApproved": true,
//             "amfValue": "£0",
//             "instantBTFlag": "N",
//             "mainBulletPoints": [
//                 "<h3>Collect 1 Avios for every £1 you spend on eligible purchases</h3><p>Put them towards flights, hotels, car hire and more. Terms and conditions apply, which you'll find under 'Barclaycard Avios reward rules' as you complete the application.</p>",
//                 "<h3>Collect 5,000 Avios if you spend £1,000 in your first 3 months</h3><p>New customers only.</p>",
//                 "<h3>British Airways cabin upgrade voucher if you spend £20,000 in a year</h3><p>To be used on an Avios Reward Flight booking – see ba.com/cabin-upgrade-voucher for terms and conditions.</p>",
//                 "<h3>Free Apple subscriptions</h3><p>Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply.</p>"
//             ],
//             "apr": "22.90",
//             "airP": "22.9",
//             "tcbiBal": "1200.00",
//             "btCapAmount": 20000.0,
//             "btFeePercentage": "null",
//             "eligibilityUrl": null,
//             "targetValue": null,
//             "btFeeMin": 0,
//             "accountFee": 0
//         },
//         {
//             "productCode": "9370",
//             "originCode": "322HD1",
//             "trackingCode": null,
//             "clouditSwitch": "Y",
//             "spProdDesc": "No fee up to 15 month balance transfer Platinum card",
//             "cardImage": "platinum-card.png",
//             "smallCardImage": "platinum-new-small.png",
//             "cardIcon": "platinum-icon.png",
//             "spPromoText": "Could be good if you're looking to transfer a balance but you don't want to pay a fee.",
//             "productCategory": "balanceTransfer",
//             "topOffer": false,
//             "spPreApproved": false,
//             "amfValue": "£0",
//             "instantBTFlag": "Y",
//             "mainBulletPoints": [
//                 "<h3>Collect 1 Avios for every £1 you spend on eligible purchases</h3><p>Put them towards flights, hotels, car hire and more. Terms and conditions apply, which you'll find under 'Barclaycard Avios reward rules' as you complete the application.</p>",
//                 "<h3>Collect 5,000 Avios if you spend £1,000 in your first 3 months</h3><p>New customers only.</p>",
//                 "<h3>British Airways cabin upgrade voucher if you spend £20,000 in a year</h3><p>To be used on an Avios Reward Flight booking – see ba.com/cabin-upgrade-voucher for terms and conditions.</p>",
//                 "<h3>Free Apple subscriptions</h3><p>Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply.</p>"
//             ],
//             "apr": "21.90",
//             "airP": "21.9",
//             "tcbiBal": "1200.00",
//             "btCapAmount": 20000.0,
//             "btFeePercentage": "2.9",
//             "eligibilityUrl": null,
//             "targetValue": null,
//             "btFeeMin": 0,
//             "accountFee": 0
//         },
//         {
//             "productCode": "9371",
//             "originCode": "322HD1",
//             "trackingCode": null,
//             "clouditSwitch": "N",
//             "spProdDesc": "Up to 30 month balance transfer Platinum card",
//             "cardImage": "platinum-card.png",
//             "smallCardImage": "platinum-new-small.png",
//             "cardIcon": "platinum-icon.png",
//             "spPromoText": "For maximum flexibility, giving you more time to pay off your balance.",
//             "productCategory": "purchaseOffer",
//             "topOffer": true,
//             "spPreApproved": true,
//             "amfValue": "£0",
//             "instantBTFlag": "Y",
//             "mainBulletPoints": [
//                 "<h3>Collect 1 Avios for every £1 you spend on eligible purchases</h3><p>Put them towards flights, hotels, car hire and more. Terms and conditions apply, which you'll find under 'Barclaycard Avios reward rules' as you complete the application.</p>",
//                 "<h3>Collect 5,000 Avios if you spend £1,000 in your first 3 months</h3><p>New customers only.</p>",
//                 "<h3>British Airways cabin upgrade voucher if you spend £20,000 in a year</h3><p>To be used on an Avios Reward Flight booking – see ba.com/cabin-upgrade-voucher for terms and conditions.</p>",
//                 "<h3>Free Apple subscriptions</h3><p>Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply.</p>"
//             ],
//             "apr": "21.90",
//             "airP": "21.9",
//             "tcbiBal": "1200.00",
//             "btCapAmount": 20000.0,
//             "btFeePercentage": "2.9",
//             "eligibilityUrl": null,
//             "targetValue": null,
//             "btFeeMin": 0,
//             "accountFee": 0
//         },
//         {
//             "productCode": "9372",
//             "originCode": "322HD1",
//             "trackingCode": null,
//             "clouditSwitch": "N",
//             "spProdDesc": "Barclaycard Avios",
//             "cardImage": "avios-card.png",
//             "smallCardImage": "avios-card-small.png",
//             "cardIcon": "avios-icon.png",
//             "spPromoText": "Collect 1 Avios for every £1 you spend on eligible purchases. T&Cs & exclusions apply.",
//             "productCategory": "suggested,rewards",
//             "topOffer": true,
//             "spPreApproved": true,
//             "amfValue": "£0",
//             "instantBTFlag": "Y",
//             "mainBulletPoints": [
//                 "<h3>Collect 1 Avios for every £1 you spend on eligible purchases</h3><p>Put them towards flights, hotels, car hire and more. Terms and conditions apply, which you'll find under 'Barclaycard Avios reward rules' as you complete the application.</p>",
//                 "<h3>Collect 5,000 Avios if you spend £1,000 in your first 3 months</h3><p>New customers only.</p>",
//                 "<h3>British Airways cabin upgrade voucher if you spend £20,000 in a year</h3><p>To be used on an Avios Reward Flight booking – see ba.com/cabin-upgrade-voucher for terms and conditions.</p>",
//                 "<h3>Free Apple subscriptions</h3><p>Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply.</p>"
//             ],
//             "apr": "21.90",
//             "airP": "21.9",
//             "tcbiBal": "1200.00",
//             "btCapAmount": 20000.0,
//             "btFeePercentage": "2.9",
//             "eligibilityUrl": null,
//             "targetValue": null,
//             "btFeeMin": 0,
//             "accountFee": 0
//         },
//         {
//             "productCode": "9373",
//             "originCode": "322HD1",
//             "trackingCode": null,
//             "clouditSwitch": "Y",
//             "spProdDesc": "Barclaycard Avios Plus",
//             "cardImage": "avios-plus-card.png",
//             "smallCardImage": "avios-plus-card-small.png",
//             "cardIcon": "avios-plus-icon.png",
//             "productCategory": "rewards",
//             "topOffer": true,
//             "spPreApproved": true,
//             "amfValue": 20,
//             "instantBTFlag": "N",
//             "spPromoText": "Collect 1.5 Avios for every £1 you spend on eligible purchases",
//             "mainBulletPoints": [
//                 "<h3>Collect 1 Avios for every £1 you spend on eligible purchases</h3><p>Put them towards flights, hotels, car hire and more. Terms and conditions apply, which you'll find under 'Barclaycard Avios reward rules' as you complete the application.</p>",
//                 "<h3>Collect 5,000 Avios if you spend £1,000 in your first 3 months</h3><p>New customers only.</p>",
//                 "<h3>British Airways cabin upgrade voucher if you spend £20,000 in a year</h3><p>To be used on an Avios Reward Flight booking – see ba.com/cabin-upgrade-voucher for terms and conditions.</p>",
//                 "<h3>Free Apple subscriptions</h3><p>Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply.</p>"
//             ],
//             "apr": "21.90",
//             "airP": "21.93",
//             "tcbiBal": "1200.00",
//             "btCapAmount": 20000.0,
//             "btFeePercentage": "2.9",
//             "eligibilityUrl": null,
//             "targetValue": null,
//             "btFeeMin": 0,
//             "accountFee": 20
//         }
//     ],
//     "channelId": 1,
//     "taggingSwitch": "ON"
// }

let _data: ShoppingPageDataType = 
// {
//     "responseCode": "SUCCESS",
//     "journeyType": "NPS",
//     "mainBulletPointsCount": null,
//     "offers": null,
//     "channelId": 4,
//     "taggingSwitch": null
// }

{
    "responseCode": "SUCCESS",
    "journeyType": "NPS",
    "psc": "9991",
    "bsp": "9992",
    "mainBulletPointsCount": "5",
    "offers": [
        {
            "productCode": "10347",
            "originCode": "246MD1",
            "trackingCode": "BAR0260033",
            "spProdDesc": "Barclaycard Avios Plus",
            "spPromoText": "Collect 1.5 Avios for every £1 you spend on eligible purchases. T&Cs and exclusions apply.",
            "cardImage": "avios-plus-card.png",
            "smallCardImage": "avios-plus-card-small.png",
            "mainBulletPoints": [
                "Collect 1.5 Avios for every £1 you spend on eligible purchases| Put them towards flights, hotels, car hire and more. Terms and conditions apply, which you'll find under 'Barclaycard Avios reward rules' as you complete the application.",
                "Collect 25,000 Avios if you spend £3,000 in your first 3 months | New customers only.",
                "British Airways cabin upgrade voucher if you spend £10,000 in a year | To be used on an Avios Reward Flight booking  – see ba.com/cabin-upgrade-voucher for terms and conditions. ",
                "Airport lounge membership | Access to over 1,000 airport lounges worldwide at a discounted rate of £18.50 per lounge pass, per person",
                "Free Apple subscriptions |Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply."
            ],
            "apr": "77.6",
            "airP": "27.9",
            "tcbiBal": "1200.00",
            "spPreApproved": true,
            "productCategory": "Rewards",
            "amfValue": 20,
            "topOffer": true,
            "spInstantBTFlag": false,
            "btCapAmount": 0.0,
            "btFeePercentage": "null",
            "eligibilityUrl": "https://gbrpsr000007586.intranet.barcapint.com:8051/uka-client-index.html#customer-quote/pc=10347&mc=246MD1&tc=BAR0260033&sn=1",
            "targetValue": "_self",
            "clouditSwitch": "Y",
            "btFeeMin": 0
        },
        {
            "productCode": "10343",
            "originCode": "244MD1",
            "trackingCode": "BAR0260033",
            "spProdDesc": "Barclaycard Avios",
            "spPromoText": "Collect 1 Avios for every £1 you spend on eligible purchases. T&Cs and exclusions apply.",
            "cardImage": "avios-card.png",
            "smallCardImage": "avios-card-small.png",
            "mainBulletPoints": [
                "Collect 1 Avios for every £1 you spend on eligible purchases| Put them towards flights, hotels, car hire and more. Terms and conditions apply, which you'll find under 'Barclaycard Avios reward rules' as you complete the application.",
                "Collect 5,000 Avios if you spend £1,000 in your first 3 months | New customers only.",
                "British Airways cabin upgrade voucher if you spend £10,000 in a year | To be used on an Avios Reward Flight booking  – see ba.com/cabin-upgrade-voucher for terms and conditions. ",
                "Airport lounge membership | Access to over 1,000 airport lounges worldwide at a discounted rate of £18.50 per lounge pass, per person",
                "Free Apple subscriptions |Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply."
            ],
            "apr": "27.9",
            "airP": "27.9",
            "tcbiBal": "1200.00",
            "spPreApproved": true,
            "productCategory": "Rewards",
            "amfValue": 0,
            "topOffer": false,
            "spInstantBTFlag": false,
            "btCapAmount": 0.0,
            "btFeePercentage": "null",
            "eligibilityUrl": "https://gbrpsr000007586.intranet.barcapint.com:8051/uka-client-index.html#customer-quote/pc=10343&mc=244MD1&tc=BAR0260033&sn=1",
            "targetValue": "_self",
            "clouditSwitch": "Y",
            "btFeeMin": 0
        },
        {
            "productCode": "9302",
            "originCode": "133MD1",
            "trackingCode": "BAR0260033",
            "spProdDesc": "Amazon Barclaycard earn rewards at Amazon and on your everyday spending",
            "spPromoText": "Get 1% rewards at Amazon and partners and 0.5% you spend everywhere else.",
            "cardImage": "arrow-card.png",
            "smallCardImage": "arrow-card-small.png",
            "mainBulletPoints": [
                "0.25% cashback | on all your spend",
                "No fees abroad | you’ll be able to withdraw cash from an ATM or buy your souvenirs without any charges and benefit from Visa’s competitive exchange rate",
                "Free Apple subscriptions |Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply."
            ],
            "apr": "22.9",
            "airP": "22.9",
            "tcbiBal": "1200.00",
            "spPreApproved": true,
            "productCategory": "Rewards",
            "amfValue": 0,
            "topOffer": true,
            "spInstantBTFlag": false,
            "btCapAmount": 0.0,
            "btFeePercentage": "null",
            "eligibilityUrl": "https://www.amazon.co.uk/dp/B0BH98211K?plattr=BAR00001&ref=BAR00001",
            "targetValue": "_blank",
            "clouditSwitch": "Y",
            "btFeeMin": 0
        },
        {
            "productCode": "9464",
            "originCode": "215MD1",
            "trackingCode": "BAR0260033",
            "spProdDesc": "Forward Credit Card",
            "spPromoText": "A card for if you're new to credit or have a limited credit history.",
            "cardImage": "forward-card.png",
            "smallCardImage": "forward-card-small.png",
            "mainBulletPoints": [
                "APR reduction | Our Price Promise – 3% interest rate reduction if you make all your payments on time and stay within your credit limit for the first year. Keep it going in the second year and you’ll get another 2% reduction.",
                "0% interest on purchases for 3 months | From the date you open your account.",
                "Free Apple subscriptions | Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply."
            ],
            "apr": "33.9",
            "airP": "33.9",
            "tcbiBal": "1200.00",
            "spPreApproved": false,
            "productCategory": "Credit Building",
            "amfValue": 0,
            "topOffer": true,
            "spInstantBTFlag": false,
            "btCapAmount": 0.0,
            "btFeePercentage": "null",
            "eligibilityUrl": "https://gbrpsr000007586.intranet.barcapint.com:8051/uka-client-index.html#customer-quote/pc=9464&mc=215MD1&tc=BAR0260033&sn=1",
            "targetValue": "_self",
            "clouditSwitch": "Y",
            "btFeeMin": 0
        },
        {
            "productCode": "10216",
            "originCode": "234MD1",
            "trackingCode": "BAR0260033",
            "spProdDesc": "Barclaycard Rewards",
            "spPromoText": "Earn cashback on every pound you spend.",
            "cardImage": "flex-rewards-card.png",
            "smallCardImage": "flex-rewards-card-small.png",
            "mainBulletPoints": [
                "0.25% cashback | on all your spend",
                "No fees abroad | you’ll be able to withdraw cash from an ATM or buy your souvenirs without any charges and benefit from Visa’s competitive exchange rate",
                "Free Apple subscriptions |Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply."
            ],
            "apr": "25.9",
            "airP": "25.9",
            "tcbiBal": "1200.00",
            "spPreApproved": false,
            "productCategory": "Rewards",
            "amfValue": 0,
            "topOffer": true,
            "spInstantBTFlag": false,
            "btCapAmount": 0.0,
            "btFeePercentage": "null",
            "eligibilityUrl": "https://gbrpsr000007586.intranet.barcapint.com:8051/uka-client-index.html#customer-quote/pc=10216&mc=234MD1&tc=BAR0260033&sn=1",
            "targetValue": "_self",
            "clouditSwitch": "Y",
            "btFeeMin": 0
        },
        {
            "productCode": "10351",
            "originCode": "248MD1",
            "trackingCode": "BAR0260033",
            "spProdDesc": "Barclaycard Platinum up to 22 month balance transfer card",
            "spPromoText": "A flexible card you can use for balance transfers and purchases.",
            "cardImage": "platinum-new.png",
            "smallCardImage": "platinum-new-small.png",
            "mainBulletPoints": [
                "0% interest on balance transfers for up to 22 months | From the date you open your account.",
                "2.99% balance transfer fee | Terms apply.",
                "Free Apple subscriptions | Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply."
            ],
            "apr": "23.9",
            "airP": "23.9",
            "tcbiBal": "1200.00",
            "spPreApproved": true,
            "productCategory": "Purchase Offer, Balance Transfer",
            "amfValue": 0,
            "topOffer": false,
            "spInstantBTFlag": true,
            "btCapAmount": 20000.0,
            "btFeePercentage": "2.99",
            "eligibilityUrl": "https://gbrpsr000007586.intranet.barcapint.com:8051/uka-client-index.html#customer-quote/pc=10351&mc=248MD1&tc=BAR0260033&sn=1",
            "targetValue": "_self",
            "clouditSwitch": "Y",
            "btFeeMin": 0
        },
        {
            "productCode": "10363",
            "originCode": "252MD1",
            "trackingCode": "BAR0260033",
            "spProdDesc": "Barclaycard Platinum up to 30 month balance transfer card",
            "spPromoText": "Our longest duration balance transfer card, giving you more time to pay off your balance.",
            "cardImage": "platinum-new.png",
            "smallCardImage": "platinum-new-small.png",
            "mainBulletPoints": [
                "0% interest on balance transfers for up to 30 months| From the date you open your account (a 2.98% transfer fee applies). You’ll need to make your transfers within the first 60 days to enjoy the 0% offer",
                "2.98% balance transfer fee | Terms apply.",
                "Free Apple subscriptions | Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply."
            ],
            "apr": "23.9",
            "airP": "23.9",
            "tcbiBal": "1200.00",
            "spPreApproved": true,
            "productCategory": "Balance Transfer",
            "amfValue": 0,
            "topOffer": false,
            "spInstantBTFlag": true,
            "btCapAmount": 20000.0,
            "btFeePercentage": "2.98",
            "eligibilityUrl": "https://gbrpsr000007586.intranet.barcapint.com:8051/uka-client-index.html#customer-quote/pc=10363&mc=252MD1&tc=BAR0260033&sn=1",
            "targetValue": "_self",
            "clouditSwitch": "Y",
            "btFeeMin": 0
        },
        {
            "productCode": "10357",
            "originCode": "250MD1",
            "trackingCode": "BAR0260033",
            "spProdDesc": "Barclaycard Platinum up to 15 month balance transfer card",
            "spPromoText": "Our shorter duration balance transfer card but with no transfer fee.",
            "cardImage": "platinum-new.png",
            "smallCardImage": "platinum-new-small.png",
            "mainBulletPoints": [
                "0% interest on balance transfers for up to 15 months|  From the date you open your account. You’ll need to make your transfers within the first 60 days to enjoy the 0% offer",
                "0% transfer fee on each balance transfer made |  Terms and conditions apply",
                "0% interest on purchases for up to 3 months | From the date you open your account.",
                "Free Apple subscriptions | Get up to five months of Apple TV+, Apple Music, Apple Fitness+, Apple News+ and Apple Arcade for free with your new Barclaycard. Continues as paid subscription after trial. UK only, T&Cs apply."
            ],
            "apr": "23.9",
            "airP": "23.9",
            "tcbiBal": "1200.00",
            "spPreApproved": true,
            "productCategory": "Balance Transfer",
            "amfValue": 0,
            "topOffer": false,
            "spInstantBTFlag": true,
            "btCapAmount": 20000.0,
            "btFeePercentage": "0",
            "eligibilityUrl": "https://gbrpsr000007586.intranet.barcapint.com:8051/uka-client-index.html#customer-quote/pc=10357&mc=250MD1&tc=BAR0260033&sn=1",
            "targetValue": "_self",
            "clouditSwitch": "Y",
            "btFeeMin": 0
        }
    ],
    "channelId": 1,
    "taggingSwitch": "ON"
}

export default _data;